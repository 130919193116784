import React, { useState, useEffect, useContext } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Container from "../components/Container";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import { makeStyles } from "@material-ui/core";

import Layout from "../components/layout";
import Banner from "../components/Banner";

import useLanguage from "../hooks/useLanguage";
import useUrl from "../hooks/useUrl";

import i18nContext from "../i18n-context";

const SiteMapPage = ({ data, pageContext }) => {
  const url = useUrl();
  const lang = useLanguage();
  const muiTheme = useTheme();
  const classes = useStyles();
  const i18n = useContext(i18nContext)[lang];
  const isPhone = useMediaQuery(muiTheme.breakpoints.down("xs"));

  const { title, content, excerpt, translated } = pageContext;
  const mediaItemUrl = pageContext.featuredImage?.node?.mediaItemUrl || "";

  const useWindowWidth = () => {
    const isBrowser = typeof window !== "undefined";
    const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);

    useEffect(() => {
      if (!isBrowser) return false;
      const handleResize = () => setWidth(window.innerWidth);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    });

    return width;
  };
  let windowInnerWidth = useWindowWidth();
  const mobileSizeFirst = windowInnerWidth < muiTheme.breakpoints.values.sm;
  const contentParagraph = data.wpPage.content

  return (
    <Layout pageTranslations={translated}>
      <Helmet>
        <title>{`${i18n.websiteName} - ${title} `}</title>

        {/* og tags for facebook and linkedin (except og:type and og:locale) */}
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={excerpt} />
        <meta property="og:image" content={mediaItemUrl} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="800" />
        <meta property="og:type" content="article" />
        <meta
          property="og:locale"
          content={lang === "en" ? "en_US" : lang === "tc" ? "zh_TW" : "zh_CN"}
        />

        {/* og tags for twitter */}
        <meta name="twitter:card" content="summary" />
        {/* <meta name="twitter:site" content="@nytimes" />
        <meta name="twitter:creator" content="@SarahMaslinNir" /> */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={excerpt} />
        <meta name="twitter:image" content={mediaItemUrl} />
      </Helmet>

      <Container className={classes.sansSerif}>
        <Typography variant="h3" className={classes.sitemapTitle}>
          {title}
        </Typography>
        <Divider></Divider>

        <Grid
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={6}>
            <Typography
              className={`${classes.section} ${classes.sansSerif}`}
              component="div"
              dangerouslySetInnerHTML={{ __html: contentParagraph }}
            >
            </Typography>
          </Grid>
        </Grid>

      </Container>
    </Layout>
  );
};

const useStyles = makeStyles((theme) => ({

  section: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(4),
    },
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(3),

    lineHeight: 2,
    "& h2": {
      fontWeight: 400,
    },

    "& a": {
      color: "inherit",
      textDecoration: "none"
    },

    "& p": {
      margin: 0,
    },

    "& ul": {
      margin: 0,
      "& ul": {
        fontSize: "1rem"
      }
    }

  },
  divider: {
    backgroundColor: "#B3B3B3",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: `-${theme.spacing(1)}px`,
      marginRight: `-${theme.spacing(1)}px`,
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: `-${theme.spacing(4)}px`,
      marginRight: `-${theme.spacing(4)}px`,
    },
  },

  sitemapTitle: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),

    textTransform: "uppercase",
    textAlign: "center",
    fontWeight: 501,
    fontFamily: "Open Sans",
    [theme.breakpoints.down("xs")]: {
      fontSize: 44,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
  },

  sansSerif: {
    fontFamily: "Open Sans",
  },

  fontWeightNormal: {
    fontWeight: 400,
  },

}));

//    $locale: String
export const query = graphql`
  query sitemapPageQuery($locale: String) {
    wpPage(pageType: {pageType: {eq: "11"}}, locale: {locale: {eq: $locale}}) {
      pageType {
        pageType
      }
      id
      title
      content
      locale {
        locale
      }
    }
  }
`;

export default SiteMapPage;
